import axios from '@/plugins/axios';

export default {
  getOrganisation(config = {}) {
    return axios.get('/v1/organisation', config);
  },
  updateOrganisation(data, config = {}) {
    return axios.put('/v1/organisation', data, config);
  },
  updateOrganisationLogo(data, config = {}) {
    return axios.post('/v1/organisation/logo', data, config);
  },
  deleteOrganisationLogo(config = {}) {
    return axios.delete('/v1/organisation/logo', config);
  },
};
