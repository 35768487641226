<template>
  <div>
    <page-heading :heading="modelOriginal.name"/>
    <loading-wrapper>
      <form-open class="max-w-2xl" @submit="update">
        <debug>{{ model }}</debug>
        <div class="mt-2 grid grid-cols-2 gap-4">
          <form-text
            :form-id="formId"
            class="col-span-full"
            input-id="name"
            form-error-id="name"
            :label="$t('model.organisation.name')"
            v-model="model.name"
          />
          <div class="col-span-full">
            <span class="block text-sm text-gray-700 truncate mb-1">Logo</span>
            <div class="space-y-2">
              <div v-if="!imgSrcError" class="border border-dashed border-gray-300 w-24">
                <template v-if="model.logo">
                  <img :src="model.logo" @error="setImgSrcError(true)" @load="setImgSrcError(false)"/>
                </template>
                <template v-else>
                <span class="overflow-hidden bg-gray-100">
                  <app-svg class="h-full w-full text-gray-300 p-4" svg="solid/camera"/>
                </span>
                </template>
              </div>
              <div v-if="imgSrcError">
                <p class="py-2">Not found</p>
              </div>
              <div class="flex justify-between w-24">
                <a href="#" class="text-xs link" @click="openModal('logo')">{{ $t('update') }}</a>
                <a href="#" class="text-xs text-red-600 link" @click="deleteLogo" v-if="model.logo">{{ $t('delete') }}</a>
              </div>
              <div class="flex justify-between">
                <p class=" text-xs">Recommended width of 400px and height of 400px.</p>
              </div>
            </div>
          </div>
          <form-text
            :form-id="formId"
            :label="$t('model.organisation.meta.website_address')"
            :required="false"
            class="col-span-full"
            form-error-id="meta.website_address"
            input-id="meta-website-address"
            v-model="model.meta.website_address"
          />
          <form-textarea
            :form-id="formId"
            :label="$t('model.organisation.meta.info')"
            :required="false"
            @submit="update"
            class="col-span-full"
            form-error-id="meta.info"
            input-id="meta-info"
            v-model="model.meta.info"
          />
          <form-select
            :form-id="formId"
            :label="$t('default_notification_groups')"
            :multiple="true"
            :options="data.default_notification_groups"
            :required="false"
            class="col-span-full"
            form-error-id="default_notification_groups"
            input-id="default-notification-groups"
            v-model="model.default_notification_groups"
          />
        </div>

        <div class="flex justify-end">
          <app-button
            class="mt-4"
            :label="$t('update')"
            :disabled="loading"
            :loading="loading"
          />
        </div>
      </form-open>
    </loading-wrapper>

    <modal :active="modals.logo" @close="closeLogoModal">
      <div class="w-full space-y-2">
        <validation-errors :form-id="formIds.logo"/>
        <form-files
          :files="logo"
          :multiple="false"
          :server="{
            process: uploadLogo,
          }"
          @processfiles="refreshLogo"
          class="w-full"
          name="file"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import ApiNotificationGroupService from '@/services/api/notification_group';
import ApiOrganisationService from '@/services/api/organisation';
import Common from '@/mixins/common';

export default {
  data() {
    return {
      data: {
        default_notification_groups: [],
      },
      formId: 'updateOrganisation',
      formIds: {
        logo: 'logo',
      },
      logo: [],
      modals: {
        logo: false,
      },
      model: {
        meta: {},
      },
      modelOriginal: {},
      imgSrcError: false,
    };
  },
  methods: {
    closeLogoModal() {
      this.logo = [];
      this.closeModal('logo');
      this.setImgSrcError(false);
    },
    deleteLogo() {
      ApiOrganisationService.deleteOrganisationLogo()
        .then(() => {
          this.model.logo = null;
          this.setImgSrcError(false);
        })
        .catch(() => {});
    },
    get() {
      return ApiOrganisationService.getOrganisation({
        params: {
          include: [
            'media',
            'meta',
            'notificationGroups',
          ],
        },
      });
    },
    refreshLogo() {
      this.get()
        .then((response) => {
          const { data } = response.data;
          this.model = {
            ...this.model,
            logo: data.logo,
          };
          this.setImgSrcError(false);
        });
    },
    resourceToModel(data) {
      const model = _.pick(data, [
        'name',
        'logo',
      ]);
      model.meta = _.pick(data.meta, [
        'website_address',
        'info',
      ]);

      const defaultNotificationGroups = _.filter(data.notification_groups, (notificationGroup) => notificationGroup.default);
      model.default_notification_groups = _.map(defaultNotificationGroups, (notificationGroup) => notificationGroup.id); // `${}`

      return model;
    },
    update() {
      ApiOrganisationService
        .updateOrganisation(this.model, {
          formId: this.formId,
          showMessage: true,
        })
        .then((response) => {
          const { data } = response.data;

          this.model = this.resourceToModel(data);
          this.modelOriginal = { ...this.model };
        })
        .catch(() => {});
    },
    // eslint-disable-next-line no-unused-vars
    uploadLogo(fieldName, file, metadata, load, error, progress, abort, transfer, options) {
      const formData = new FormData();
      formData.append('logo', file, file.name);
      ApiOrganisationService.updateOrganisationLogo(formData, {
        formId: this.formIds.logo,
        onUploadProgress: (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        },
      })
        .then((response) => {
          const { data } = response.data;
          this.setImgSrcError(false);
          load(data.uuid);
        })
        .catch(() => {});

      return {
        abort: () => {
          abort();
        },
      };
    },
    setImgSrcError(val) {
      this.imgSrcError = val;
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    ApiNotificationGroupService.getOrganisationNotificationGroups({
      params: {
        per_page: -1,
      },
    }).then((response) => {
      const { data } = response.data;

      this.data.default_notification_groups = this.toSelectOptions(data);
    }).catch(() => {});

    this.get()
      .then((response) => {
        const { data } = response.data;

        this.model = this.resourceToModel(data);
        this.modelOriginal = { ...this.model };
      })
      .catch(() => {});
  },
};
</script>
